import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import ArrowText from "../components/atoms/ArrowText"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/pro-x-002.png"
import gif from "../../static/images/pro-x-g.gif"
import logo1 from "../../static/images/pro-x-logos-001.png"
import logo2 from "../../static/images/pro-x-logos-002.png"
import GetInTouch from "../components/molecules/GetInTouch"

function ProjectX() {
  return (
    <>
      <Seo
        title="The future of connecting with colleagues | Craig Walker & partners"
        image={thumb}
        description="The COVID-19 pandemic accelerated the shift to remote work for many knowledge workers."
      />

      <Splash text={<>The future of connecting with colleagues</>} work={true}>
        <p>
          <b>
            The COVID-19 pandemic accelerated the shift to remote work for many
            knowledge workers. This raised new challenges, particularly around
            how to keep people connected while physically apart.
          </b>
        </p>
        <p>
          Craig Walker embarked on two separate but related programs of research
          to understand the implications of this ‘new normal’ in the
          Asia-Pacific region. One with DesignSingapore Council in collaboration
          with Agency, and a second with WorkSafe Victoria for Melbourne Design
          Week.
        </p>
        <p>
          Using the research as a springboard, we were inspired to try to solve
          some of the key challenges ourselves. We designed a speculative
          device, the ‘Walker Talker’ to help keep people connected, even while
          working remotely. This device isn’t just another phone or device, but
          is meant to mimic the ways people connect when in the same physical
          space – especially the unplanned or spontaneous communication which
          happens routinely in office environments.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage transparent>
          <Image imgName="walker-talker-4.png" />
        </WorkImage>

        <Numbers>
          <Number number={3} text="Projects across Singapore and Australia" />
          <Number number={33} text="Ideas designed and tested" />
          <Number number={1} text="Speculative design object" />
        </Numbers>

        <WorkTextBlock>
          <h2>
            The Walker Talker is a new daily ritual to keep knowledge workers
            cool, calm, and connected.
          </h2>
          <p>
            Much like a walkie talkie, the device connects you to a colleague at
            random, enabling a spontaneous conversation. This helps combat the
            challenges of feeling isolated, including lagging motivation and
            screen-based anxiety. This is a device which encourages people to
            step away fom their screens, pick up the ‘Walker Talker’ and talk to
            whoever is on the other end while going for a walk.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="walker-talker-1.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <p>
            Someone simply picks up the device, and others are alerted that
            someone is online, though they don’t know who. The colleague can
            then connect and go on a short walk ‘together’ and have a chat.
          </p>
          <p>
            When you use the device, you also unlock a surprise. This will be a
            simple randomised link from a trove of interesting articles, which
            you can ‘unwrap’ after returning from the walk.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <img style={{width: "100%"}} src={gif} alt="Project X" />
        </WorkImage>

        <WorkTextBlock>
          <h2>
            Building to learn: defining experience principles through
            prototyping
          </h2>
          <p>
            One particularly acute challenge we wanted to tackle was the anxiety
            caused through isolation and the lack of regular informal social
            moments with colleagues. Online tools existed to combat this, but
            nothing we had seen was able to create the sense of spontaneity or
            serendipity which mimics the experience of running into a colleague
            by the water cooler.
          </p>
          <p>
            Through building, testing and iterating mulitple rounds of low
            fidelity prototypes, we developed a set of guiding principles. These
            principles, seen below, helped us get closer to achieving the kind
            of engagement we wanted, and wouldn’t create the anxiety of being
            yet another task to do.
          </p>
        </WorkTextBlock>
        <WorkImage>
          <Image imgName="pro-x-003.png" />
        </WorkImage>
        <WorkImage>
          <Image imgName="pro-x-004.png" />
        </WorkImage>
        <WorkTextBlock>
          <img
            src={logo1}
            alt="Project X"
          />
          <h2>The New Work State of Mind</h2>
          <p>
            ‘The New Work State of Mind’ was a research project and live event,
            conducted for Melbourne Design Week 2021, in collaboration with
            WorkSafe Victoria.
          </p>
          <p>
            This in-depth research was centred around three core challenges:
            Equity, Guilt and Motivation. The ‘flip’ to working from home for
            many knowledge and white collar workers turned work from a place to
            go to a mindset to adopt.
          </p>
          <p>
            With over 200 attendees, this event brought together experts,
            legislators and designers to understand the impact of this flip, and
            design ways to protect the mental health of workers in the future.
          </p>
          <p>
            We summarised our findings in a{" "}
            <a
              style={{borderBottom: "1px solid #000"}}
              target="_blank"
              rel="noopener noreferrer"
              href="https://newworkstateofmind.craigwalker.com.au/"
            >
              microsite
            </a>{" "}
            and report.
          </p>
          <p>
            This research became the foundation for developing the ‘Walker
            Talker’.
          </p>
        </WorkTextBlock>
        <WorkImage transparent>
          <Image imgName="pro-x-005.png" />
        </WorkImage>
        <WorkImage>
          <Image imgName="walker-talker-2.jpg" />
        </WorkImage>
        <WorkTextBlock>
          <img
            src={logo2}
            alt="Project X"
          />
          <h2>What’s next for Singaporean workers?</h2>
          <p>
            Craig Walker collaborated with the DesignSingapore Council and
            Agency to develop tools and approaches to help Singaporean workers
            navigate ‘what’s next’. We sought to understand some of the common
            challenges Singaporean workers are facing following the shift to
            remote work and its impacts.
          </p>
          <p>
            Through our research, we spoke to people across all levels of
            organisations, from interns to business owners so we could develop
            an understanding of impacts across levels of seniority.
          </p>
          <p>
            Together we published a research study and set of tools to help
            spark and direct conversation and action across organisations. Our
            research and insights also formed the foundation for developing the
            ‘Walker Talker’.
          </p>
        </WorkTextBlock>
        <WorkImage transparent>
          <Image imgName="pro-x-007.png" />
        </WorkImage>
        <WorkTextBlock>
          <h2>Creating a shared vision of working from home</h2>
          <p>
            We produced a toolkit to to help companies facilitate a design-first
            approach to remote work. The resource contains exercises that help
            build empathy, break down bias, and encourage openness while working
            remotely.
          </p>
          <p>
            Each exercise is designed to help organisations reflect, and to
            convert these reflective conversations into actionable pathways they
            can implement right away.
          </p>
          <p>
            The toolkit is available online or in hard copy, meaning it is
            accessible whether working remotely or face-to-face. It was designed
            to work in any level of the business, and across industries to help
            create a shared vision of remote work moving forward.
          </p>
        </WorkTextBlock>
        <WorkImage transparent>
          <Image imgName="walker-talker-3.jpg" />
        </WorkImage>

        <GetInTouch dataView="KnowNow" />
        <RelatedWork exclude="walker-talker" />
      </ContentHolder>
    </>
  )
}

export default ProjectX
